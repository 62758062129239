import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.2.61722" />
      <meta name="build-date" content="2024-11-18T19:14:46Z" />
    </Head>
  );
};

export default Versioning;
